<template>
    <div>
        <div >
            <div>
                <el-button type="primary" @click="addBtn" size="mini">
					添加优惠券 
				</el-button>
				<span style="color: red;margin-left: 10px;">(每个人未使用的优惠券最多20张)</span> 
            </div>
        </div>
         <br>
        <el-table  :data="list" border style="width: 100%">
			<el-table-column align="center" label="优惠券名称">
				<template slot-scope="scope">
				   <div>
					    {{returnCouponName(scope.row)}}
				   </div>
				</template>
			</el-table-column>
           <el-table-column prop="add_time" label="添加时间" width="180"  align="center" >
            </el-table-column>
            <el-table-column prop="update_time" label="更新时间" width="180"  align="center" >
            </el-table-column>
			<el-table-column align="center" label="状态">
				<template slot-scope="scope">
				   <div>
						<div>
						  <el-tag v-if="scope.row.static==0" type="danger" effect="dark">禁用</el-tag>
						  <el-tag v-else  effect="dark">启用</el-tag>
						</div>
				   </div>
				</template>
			</el-table-column>
			<el-table-column prop="num" label="数量(张)"  align="center" >
			</el-table-column>
			<el-table-column prop="get_num" label="每人可领张数"  align="center" >
			</el-table-column>
			<el-table-column align="center" width="260" label="使用时间">
				<template slot-scope="scope">
				   <div>
						<div>开始时间：{{scope.row.start_time}}</div>
						<div>结束时间：{{scope.row.end_time}}</div>
				   </div>
				</template>
			</el-table-column>
           <el-table-column  label="操作"  fixed="right"  align="center">
                <template slot-scope="scope">
					<el-button @click="update(scope.row)" type="text" size="small">更新</el-button>
                    <el-button type="text" size="small" @click="del(scope)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
                :title="title"
                :visible.sync="isShow"
                width="700px"
        >
            <el-form  :model="form_info" label-width="160px">
                <el-form-item label="类型">
                    <el-radio-group v-model="form_info.type">
                        <el-radio label="1">优惠券</el-radio>
                        <el-radio label="2">折扣券</el-radio>
                    </el-radio-group>
                </el-form-item>
				<el-form-item label="满足金额">
				  <el-input type="number" style="width: 100px;" v-model="form_info.money" ></el-input>元
				</el-form-item>
                <el-form-item label="折扣数" v-if="form_info.type==2">
                  <el-input-number v-model="form_info.rebate"  :min="1" :max="9"></el-input-number>折
				</el-form-item>
				<el-form-item label="优惠金额" v-if="form_info.type==1">
				  <el-input type="number" style="width: 100px;" v-model="form_info.discount_money"></el-input>元
				</el-form-item>
				<el-form-item label="状态">
				    <el-radio-group v-model="form_info.static">
				        <el-radio label="0">禁用</el-radio>
				        <el-radio label="1">启用</el-radio>
				    </el-radio-group>
				</el-form-item>
				<el-form-item label="会员是否可以自己领取">
				    <el-radio-group v-model="form_info.is_give">
				        <el-radio label="0">否</el-radio>
				        <el-radio label="1">是</el-radio>
				    </el-radio-group>
				</el-form-item>
				<el-form-item label="数量">
				  <el-input type="number" style="width: 100px;" v-model="form_info.num"></el-input>张
				</el-form-item>
				<el-form-item label="每人可领张数">
				  <el-input type="number" style="width: 100px;" v-model="form_info.get_num"></el-input>张
				</el-form-item>
				<el-form-item label="使用时间">
				   <el-date-picker
				       size="small"
				       v-model="time"
				       type="datetimerange"
				       value-format="yyyy-MM-dd HH:mm:ss"
				       range-separator="至"
				       start-placeholder="开始日期"
				       end-placeholder="结束日期">
				   </el-date-picker>	   
				</el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="isShow = false">取 消</el-button>
                    <el-button type="primary" @click="confirm">确 定</el-button>
                </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                title:'添加优惠券',
                list:[],
                isShow:false,
				time:[],//开始结束使用时间
                form_info:{
					id:'',
                    type:'1',//类型 1优惠券 2折扣券
					rebate:"9",//折扣数 1-9
					money:'',//满足金额
					discount_money:'',//优惠金额
					static:'1', //状态 0禁用 1启用
					num:'', //数量
					get_num:'1',//每人可以领张数
					start_time:"",//开始时间
					end_time:'',//结束时间
					is_give:'1', //是否可以领取 0不可以 1可以
                },
                
            }
        },
        mounted(){
            document.title='优惠券列表'
            this.lodeData()
        },
        methods:{
			
            confirm(){
                let _this=this
				let item = this.form_info
				if(this.list.length>=5 && this.title=='添加优惠券'){
					return this.$message.error('最多添加5种优惠券')
				}
				if(!item.money) return this.$message.error('请输入满足金额')
				if(item.type==1){
					if(!item.discount_money){
						return this.$message.error('请输入优惠金额')
					}
				}
				if(item.type==2){
					if(!item.rebate){
						return this.$message.error('请输入折扣数')
					} 
				}
				if(!item.num) return this.$message.error('请输入优惠券数量')
				if(!item.get_num) return this.$message.error('请输入每人可领取张数')
				
				if(!this.time.length) return this.$message.error('请选择开始结束时间')
                this.form_info.start_time = this.time[0]
				this.form_info.end_time = this.time[1]			
                this.$post('/pc/coupon/addCoupon',_this.form_info,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.$message.success(result.msg)
						_this.isShow = false
						_this.lodeData()
                    }
                })
            },
            del(el){
                let _this=this
                if(confirm('确定删除')){
                    var index = el.$index
                    var id=el.row.id
                    this.$post('/pc/coupon/delCoupon',{id:id},function (res) {
                        let result = res.data
                        if(result.code==200){
                            _this.list.splice(index,1)
                            _this.$message.success(result.msg)
                        }
                    })
                }
            },
            addBtn(){
                this.isShow=true
                this.title='添加优惠券'
                this.form_info = {
					id:'',
                    type:'1',//类型 1优惠券 2折扣券
                    rebate:"9",//折扣数 1-9
                    money:'',//满足金额
                    discount_money:'',//优惠金额
                    static:'1', //状态 0禁用 1启用
                    num:'', //数量
                    get_num:'1',//每人可以领张数
                    start_time:"",//开始时间
                    end_time:'',//结束时间
					is_give:'1', //是否可以领取 0不可以 1可以
                }
				this.time = []
            },
            update(row){
                this.title='更新优惠券'
                this.isShow=true
				for(let i in this.form_info){
					this.form_info[i] = row[i]
				}
				this.time = [this.form_info.start_time,this.form_info.end_time]
            },
			lodeData(){
			    let _this=this
			    this.$post('/pc/coupon/getCoupon',{},function (res) {
			        let result = res.data
			        if(result.code==200){
			            _this.list=result.data
			        }
			    })
			},
        }
    }
</script>
<style>

</style>
